import i18next from "i18next";

/**
 * Return a key translated for i18next
 *
 * @param keyToTranslate Key of the Translation Json
 * @param formatType format to apply: 'capitalize', 'uppercase' or 'title'
 * @param interpolateParams Object containing dynamic values ​​into translation
 */
export const translateAndFormat = (
	keyToTranslate: string,
	formatType: "capitalize" | "uppercase" | "title" | "camelCase",
	interpolateParams: Record<string, any> = {},
) => i18next.format(i18next.t(keyToTranslate, interpolateParams), formatType);
