import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import pdfMake from "pdfmake/build/pdfmake";
import { Content, ContextPageSize, TDocumentDefinitions } from "pdfmake/interfaces";
import { translateAndFormat } from "src/app/i18next";
import { formatDate } from "src/app/i18next/formatDate";
import { ExportType } from "src/app/models/exportType";
import { AssetPrintViewModel, InspectionListPrintViewModel, TelemetryPrintViewModel } from "src/app/models/inspection-print-view.models";
import { ClosedDefectTableViewModel, OpenDefectTableViewModel } from "src/app/models/open-defect-table.models";
import { InspectionGet } from "src/app/models/openAPIAliases";
import { ValueVector } from "src/app/models/value-vector.model";
import { InspectionHistoryService } from "src/app/views/inspection-history/service/inspection-history.service";
import {
  GpsMissingInspectionViewModel,
  MissingInspectionsAssetViewModel,
  MissingInspectionsInspectorViewModel,
} from "src/app/views/missing-inspections/missing-inspections.component";
import { environment } from "src/environments/environment";
import { findUnitIndex } from "src/utils/findUnitIndex/findUnitIndex";
import { getSeverity } from "src/utils/getSeverity/getSeverity";
import { newDate } from "src/utils/newDate/newDate";
import { timeDifference } from "src/utils/timeDifference/timeDifference";
import { BooleanTransformService } from "../boolean-transform.service";
import { InspectionPrintViewModelService } from "../inspection-print-view-model.service";
import { LanguageDictionaryService } from "../language-dictionary/language-dictionary.service";
import { LocaleService } from "../locale/locale.service";

pdfMake.fonts = {
  ...pdfMake.fonts,
  Roboto: {
    normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
};

type MissingInspectionsFocus = "inspectors" | "assets" | "assets-gps";

const pdfConfig = (title: string) => ({
  pageSize: "LEGAL",
  pageOrientation: "landscape",
  header: (currentPage: number, pageCount: number, pageSize: ContextPageSize) =>
    [
      { text: `${translateAndFormat(title, "title")} (Page ${currentPage} of ${pageCount})`, style: "header" },
      {
        alignment: currentPage % 2 ? "right" : "left",
        style: "header",
        text: `${currentPage} of ${pageCount}`,
      },
      { canvas: [{ type: "rect", x: 170, y: 32, w: pageSize.width - 170, h: 40 }] },
    ] as Content,
  footer: (currentPage: number, pageCount: number) =>
  ({
    text: `${currentPage.toString()} of ${pageCount}`,
    style: "footer",
  } as Content),
  styles: {
    document: {
      fontSize: 12,
    },
    header: {
      alignment: "center",
      bold: true,
      fontSize: 18,
      margin: 2,
    },
    footer: {
      alignment: "right",
      font: "Roboto",
      fontSize: 10,
    },
  },
});

@Injectable({
  providedIn: "root",
})
export class ExportService {
  constructor(
    private booleanTransformService: BooleanTransformService,
    private inspectionListService: InspectionHistoryService,
    private languageDictionaryService: LanguageDictionaryService,
    private localeService: LocaleService,
    private inspectionPrintViewModelService: InspectionPrintViewModelService,
  ) { }

  public exportInspectionData(inspectionIds: ReadonlyArray<string>, companyId: string, exportType: ExportType) {
    this.inspectionListService
      .getSingleInspections(inspectionIds, companyId, environment.environmentConstants.APP_ENDPOINT_EVIR)
      .subscribe((singleInspections: Array<HttpResponse<InspectionGet>>) => {
        singleInspections.sort((a, b) => inspectionIds.indexOf(a.body.id) - inspectionIds.indexOf(b.body.id));

        const data = singleInspections.map(({ body: inspection }: HttpResponse<InspectionGet>) => {
          const inspectionDetailDataNonPhotoRecords =
            inspection.inspectionDetail.inspectionDetailData?.filter(
              inspectionDetail => inspectionDetail.inspectionDetailDataType !== "photo",
            );

          const customData =
            inspectionDetailDataNonPhotoRecords.map(
              ({
                inspectionDetailContent,
                inspectionDetailDataName,
                inspectionDetailSelectedUnit,
                inspectionDetailUnitConversionPair,
              }) =>
                [
                  `${this.languageDictionaryService.getTranslations(
                    inspectionDetailDataName,
                  )} ${this.unitToDisplayInHeader(
                    inspectionDetailContent,
                    inspectionDetailSelectedUnit,
                    inspectionDetailUnitConversionPair,
                  )}`,
                  this.booleanTransformService.booleanTransformData(
                    inspectionDetailContent,
                    inspectionDetailUnitConversionPair,
                  ) ?? "",
                ] as const,
            ) ?? [];

          const inspectionData = [
            ["severity", this.severity(inspection.maxSeverity)],
            ["date", formatDate(this.getStartTime(inspection), "P")],
            ["time", formatDate(this.getStartTime(inspection), "pp")],
            ["duration", this.inspectionDuration(inspection)],
            ["asset number", inspection.inspectionAssets[0].asset.assetName],
            [
              "asset type",
              this.languageDictionaryService.getTranslations(
                inspection.inspectionAssets[0].zoneLayoutName,
              ),
            ],
            [
              "insp type",
              this.languageDictionaryService.getTranslations(
                inspection.inspectionDetail.inspectionDetailName,
              ),
            ],
            ["inspector", `${inspection.inspectorFirstName} ${inspection.inspectorLastName}`],
            ["asset location", inspection?.inspectionAssets?.[0]?.asset.assetDivision?.divisionName],
          ].map(([key, value]) => [translateAndFormat(key, "capitalize"), value ?? ""] as const);

          return Object.fromEntries([...inspectionData, ...customData]);
        });

        const fileName = translateAndFormat("inspection details", "camelCase");
        const columns = [...new Set(data.flatMap(Object.keys))];
        const patchedData = data.map(row =>
          Object.fromEntries(columns.map(column => [column, row[column] ?? ""] as const)),
        );

        exportType === ExportType.CSV
          ? this.exportCSV(patchedData, fileName)
          : this.exportPDF(patchedData, fileName, "inspection details");
      });
  }

  public exportDetailedInspectionData(inspectionIds: ReadonlyArray<string>, companyId: string) {
    this.inspectionListService
      .getSingleInspections(inspectionIds, companyId, environment.environmentConstants.APP_ENDPOINT_EVIR)
      .subscribe((singleInspections: HttpResponse<InspectionGet>[]) => {
        const inspections = singleInspections.sort((a, b) => inspectionIds.indexOf(a.body.id) - inspectionIds.indexOf(b.body.id))
          .map(({ body }) => this.inspectionPrintViewModelService.getInspectionPrintViewModel(body));

        const fileName = translateAndFormat('inspection details', 'camelCase');
        const content = this.buildDetailedInspectionDataContent(inspections);
        pdfMake.createPdf({ ...pdfConfig('inspection details'), content: content } as TDocumentDefinitions)
          .getBlob(blob => this.download({ blob, fileName, type: 'pdf' }));
      });
  }

  public exportDefectData(
    defects: ReadonlyArray<OpenDefectTableViewModel | ClosedDefectTableViewModel>,
    status: "open" | "closed",
    exportType: ExportType,
  ) {
    const data = defects.map(defect => {
      const defectData = [
        status === "open"
          ? ["severity", this.severity(defect.severity)]
          : ["severity", `${this.severity(defect.severity)} > ${translateAndFormat("none", "capitalize")}`],
        status === "open"
          ? ["date inspected", (defect as OpenDefectTableViewModel).lastInspectedDate]
          : ["repaired date", (defect as ClosedDefectTableViewModel).repairedDate],
        status === "open"
          ? ["time", (defect as OpenDefectTableViewModel).lastInspectedTime]
          : ["time", (defect as ClosedDefectTableViewModel).repairedTime],
        ["asset number", defect.reconciledAssetName],
        ["asset location", defect.assetLocation],
        ["zone", defect.zoneLabel],
        ["component", defect.componentLabel],
        ["condition", defect.conditionLabel],
      ].map(([key, value]) => [translateAndFormat(key, "capitalize"), value ?? ""] as const);

      return Object.fromEntries([...defectData]);
    });

    const fileName = translateAndFormat("defect details", "camelCase");
    const columns = [...new Set(data.flatMap(Object.keys))];
    const patchedData = data.map(row =>
      Object.fromEntries(columns.map(column => [column, row[column] ?? ""] as const)),
    );

    exportType === ExportType.CSV
      ? this.exportCSV(patchedData, fileName)
      : this.exportPDF(patchedData, fileName, "defect details");
  }

  public exportMissingInspectionData(
    missingInspections: ReadonlyArray<
      MissingInspectionsInspectorViewModel | MissingInspectionsAssetViewModel | GpsMissingInspectionViewModel
    >,
    focus: MissingInspectionsFocus,
    exportType: ExportType,
  ) {
    const data = missingInspections.map(missingInspection => {
      return Object.fromEntries(this.generateMissingInspectionsDataRow(focus, missingInspection));
    });

    const fileName = translateAndFormat("missing inspections", "camelCase");
    const columns = [...new Set(data.flatMap(Object.keys))];
    const patchedData = data.map(row =>
      Object.fromEntries(columns.map(column => [column, row[column] ?? ""] as const)),
    );

    exportType === ExportType.CSV
      ? this.exportCSV(patchedData, fileName)
      : this.exportPDF(patchedData, fileName, "missing inspections");
  }

  public exportCSV(data: ReadonlyArray<Record<string, string>>, fileName) {
    const formattedData = data.map(element =>
      Object.fromEntries(Object.entries(element).map(([key, value]) => [key, `"${value}"`])),
    );
    const BOM = "\uFEFF"; // Byte Order Mark

    this.download({
      blob: new Blob(
        [
          BOM +
          [[...Object.keys(data[0])], ...formattedData.map(element => Object.values(element))]
            .map(element => element.join(","))
            .join("\n"),
        ],
        { type: "text/csv;charset=utf-8" },
      ),
      fileName,
      type: "csv",
    });
  }

  public exportPDF(data: ReadonlyArray<Record<string, string>>, fileName: string, title: string) {
    pdfMake
      .createPdf({
        ...pdfConfig(title),
        content: {
          table: {
            headerRows: 1,
            widths: new Array(Object.keys(data[0]).length).fill("auto"),
            body: [
              // Table headers
              Object.keys(data[0]).map(text => ({ text, bold: true })),
              // Table data
              ...data.map(element => Object.values(element).map(text => ({ text, font: "Roboto" }))),
            ],
          },
        },
      } as TDocumentDefinitions)
      .getBlob(blob => this.download({ blob, fileName, type: "pdf" }));
  }

  private download = ({ blob, fileName, type }: { blob: Blob; fileName: string; type: string }) =>
    Object.assign(document.createElement("a"), {
      download: `${fileName}.${type}`,
      href: URL.createObjectURL(blob),
    }).click();

  private getStartTime(inspection: InspectionGet): Date {
    const relevantInspectionInfo = inspection.inspectionInfo.find(({ action }) => action === "start");

    return relevantInspectionInfo !== undefined ? newDate(relevantInspectionInfo.telemetry.timestamp) : null;
  }

  private severity(maxSeverity: number) {
    return getSeverity(maxSeverity) || translateAndFormat("no defects", "title");
  }

  private generateMissingInspectionsDataRow(
    focus: MissingInspectionsFocus,
    data: MissingInspectionsInspectorViewModel | MissingInspectionsAssetViewModel | GpsMissingInspectionViewModel,
  ) {
    return [
      [
        focus === "assets-gps" ? "start date / time" : "date",
        focus === "assets-gps" ? data.startTime.join(" ") : data.startTime,
      ],
      ...(focus === "inspectors"
        ? ([
          ["inspector", (data as MissingInspectionsInspectorViewModel).inspector],
          ["inspection name", (data as MissingInspectionsInspectorViewModel).inspection.name],
          ["inspection type", (data as MissingInspectionsInspectorViewModel).inspection.type.join(" ")],
          ["home location", (data as MissingInspectionsInspectorViewModel).division],
        ] as const)
        : focus === "assets-gps"
          ? ([
            ["asset id", (data as GpsMissingInspectionViewModel).asset.name],
            ["asset type", (data as GpsMissingInspectionViewModel).zoneLayout],
            ["inspection name", (data as GpsMissingInspectionViewModel).inspection.name],
            ["inspection type", (data as GpsMissingInspectionViewModel).inspection.type.join(" ")],
            ["home location", (data as GpsMissingInspectionViewModel).division],
          ] as const)
          : ([
            ["asset number", (data as MissingInspectionsAssetViewModel).asset.name],
            ["asset type", (data as MissingInspectionsAssetViewModel).asset.type],
            ["inspection name", (data as MissingInspectionsAssetViewModel).inspection.name],
            ["inspection type", (data as MissingInspectionsAssetViewModel).inspection.type.join(" ")],
            ["home location", (data as MissingInspectionsAssetViewModel).division],
          ] as const)),
    ].map(([key, value]) => [translateAndFormat(key, "capitalize"), value ?? ""]) as ReadonlyArray<
      [key: string, value: string]
    >;
  }

  private unitToDisplayInHeader(
    data: string | ValueVector,
    selectedUnit: string,
    unitsConversionPair?: ReadonlyArray<string>,
  ) {
    const unitsConversionPairDefined = (unitsConversionPair?.length ?? 0) > 0;

    return typeof data !== "string"
      ? unitsConversionPairDefined || (selectedUnit !== undefined && selectedUnit !== null)
        ? `(${unitsConversionPairDefined
          ? unitsConversionPair[findUnitIndex(this.localeService, unitsConversionPair)]
          : selectedUnit
        })`
        : ""
      : "";
  }

  private inspectionDuration(inspection: InspectionGet) {
    const start = newDate(inspection.inspectionInfo.find(info => info.action === "start").telemetry.timestamp);
    const end = newDate(inspection.inspectionInfo.find(info => info.action === "end").telemetry.timestamp);
    const timeDiff = timeDifference(end)(start);

    const hours = timeDiff.hours < 10 ? `0${timeDiff.hours}` : timeDiff.hours;
    const minutes = timeDiff.minutes < 10 ? `0${timeDiff.minutes}` : timeDiff.minutes;
    const seconds = timeDiff.seconds < 10 ? `0${timeDiff.seconds}` : timeDiff.seconds;

    return `${hours}:${minutes}:${seconds}`;
  }

  private buildDetailedInspectionDataContent(inspectionList: InspectionListPrintViewModel[]) {
    const inspectionListCount = inspectionList.length;
    return inspectionList.flatMap((inspection, index) => ([
      { text: `${translateAndFormat('inspection number of numbers', 'capitalize', { number: index + 1, numbers: inspectionListCount })}` },
      { text: '\n' },
      { text: [`${translateAndFormat('company', 'title')}:`, { text: ` ${inspection.inspectionInfo.static.companyName}`, bold: true }] },
      { text: `${translateAndFormat('inspector', 'title')}: ${inspection.inspectionInfo.static.inspector}` },
      { text: `${translateAndFormat('inspection type', 'title')}: ${inspection.inspectionInfo.static.inspectionType}` },
      { text: `${translateAndFormat('inspection start time', 'title')}: ${inspection.inspectionInfo.time.start}` },
      { text: `${translateAndFormat('inspection duration', 'title')}: ${inspection.inspectionInfo.time.duration}` },
      { text: '\n' },
      { text: `${translateAndFormat('address', 'uppercase')}: ${inspection.inspectionInfo.static.geolocation}` },
      { text: `${translateAndFormat('license', 'title')}: ${inspection.inspectionInfo.static.license}` },
      { text: `${translateAndFormat('jurisdiction type', 'title')}: ${inspection.inspectionInfo.static.jurisdiction}` },
      { text: `${translateAndFormat('vin', 'uppercase')}: ${inspection.inspectionInfo.static.vin}` },
      { text: `${translateAndFormat('power unit', 'title')}: ${inspection.inspectionInfo.static.powerUnit}` },
      { text: `${translateAndFormat('language choice', 'title')}: ${inspection.inspectionInfo.static.languageChoice}` },
      ...inspection.inspectionInfo.dynamic.map(item => ({ text: `${item.languageLabel}: ${item.inspectionDetailContent}` })),
      ...inspection.inspectionInfo.formDataPhotos.flatMap(photo => photo.images && photo.images.length
        ? [
          { text: `${photo.label}:` },
          { columns: photo.images.map(image => ({ image: (image.sanitizedImage as any).changingThisBreaksApplicationSecurity, width: 150, height: 150 })), columnGap: 10 },
        ]
        : { text: `${photo.label}: ${photo.defaultValue}` }
      ),
      { text: `${translateAndFormat('third signature', 'title')}: ${inspection.inspectionInfo.static.thirdSignature}` },
      { text: `${translateAndFormat('user agent', 'title')}: ${inspection.inspectionInfo.static.userAgent}` },
      { text: `${translateAndFormat('inspector divisions', 'title')}: ${inspection.inspectionInfo.static.inspectorDivisions}` },
      { text: `${translateAndFormat('max severity', 'title')}: ${inspection.inspectionInfo.static.maxSeverity}` },
      { text: '\n' },
      { text: `${translateAndFormat('inspection start time', 'title')}: ${inspection.inspectionInfo.time.start}` },
      { text: `${translateAndFormat('inspection end time', 'title')}: ${inspection.inspectionInfo.time.end}` },
      { text: `${translateAndFormat('inspection upload time', 'title')}: ${inspection.inspectionInfo.time.upload}` },
      { text: `${translateAndFormat('inspection certify time', 'title')}: ${inspection.inspectionInfo.time.certify}` },
      { text: '\n' },
      ...this.buildInspectionAssetContent(inspection.inspectionAssets),
      ...this.buildTelemetryContent(inspection.telemetry),
    ]));
  }

  private buildInspectionAssetContent(inspectionAssetList: AssetPrintViewModel[]) {
    if(!(inspectionAssetList && inspectionAssetList.length)) {
      return [];
    }

    return inspectionAssetList.flatMap(inspectionAsset => ([
      { text: [`${translateAndFormat('asset name', 'title')}:`, { text: ` ${inspectionAsset.assetName}`, bold: true }] },
      { text: `${translateAndFormat('asset category', 'title')}: ${inspectionAsset.assetCategory}` },
      { text: `${translateAndFormat('asset division', 'title')}: ${inspectionAsset.assetDivision}` },
      ...inspectionAsset.assetDetails.map(detail => ({ text: `${detail.label}: ${detail.value}` })),
      { text: '\n' },
      ...inspectionAsset.inspectionZones && inspectionAsset.inspectionZones.length
        ? [
          { text: `${translateAndFormat('inspection zones', 'title')}` },
          {
            table: {
              headerRows: 1,
              body: [
                // Table headers
                [
                  { text: `${translateAndFormat('zone name', 'title')}`, bold: true },
                  { text: `${translateAndFormat('time', 'title')}`, bold: true },
                  { text: `${translateAndFormat('components', 'title')}`, bold: true },
                  { text: `${translateAndFormat('defects', 'title')}`, bold: true },
                  { text: `${translateAndFormat('verification type', 'title')}`, bold: true },
                ],
                // Table data
                ...inspectionAsset.inspectionZones.map(inspectionZone => ([
                  { text: inspectionZone.languageLabel },
                  { text: `${inspectionZone.timestamp}` },
                  { text: inspectionZone.components },
                  { text: inspectionZone.inspectionDefectsYN },
                  { text: inspectionZone.verificationType },
                ])),
              ]
            }
          },
        ]
        : [
          { text: `${translateAndFormat('there are no inspection zones present on this asset in this inspection', 'capitalize')}` }
        ],
      { text: '\n' },
      ...inspectionAsset.inspectionDefects && inspectionAsset.inspectionDefects.length
        ? [
          { text: `${translateAndFormat('defects', 'title')}` },
          {
            table: {
              headerRows: 1,
              body: [
                // Table headers
                [
                  { text: `${translateAndFormat('zone', 'title')}`, bold: true },
                  { text: `${translateAndFormat('component', 'title')}`, bold: true },
                  { text: `${translateAndFormat('condition', 'title')}`, bold: true },
                  { text: `${translateAndFormat('severity', 'title')}`, bold: true },
                  { text: `${translateAndFormat('repair status', 'title')}`, bold: true },
                  { text: `${translateAndFormat('repair technician', 'title')}`, bold: true },
                  { text: `${translateAndFormat('repair date', 'title')}`, bold: true },
                  { text: `${translateAndFormat('repair resolution', 'title')}`, bold: true },
                  { text: `${translateAndFormat('repair comments', 'title')}`, bold: true },
                  { text: `${translateAndFormat('repair work order', 'title')}`, bold: true },
                  { text: `${translateAndFormat('photos', 'title')}`, bold: true },
                ],
                // Table data
                ...inspectionAsset.inspectionDefects.map((inspectionDefect: any) => ([
                  { text: inspectionDefect.zoneName },
                  { text: inspectionDefect.componentLabel },
                  { text: inspectionDefect.conditionLabel },
                  { text: inspectionDefect.severity },
                  { text: inspectionDefect.repairStatus },
                  { text: inspectionDefect.repairTechnician },
                  { text: inspectionDefect.repairDate },
                  { text: inspectionDefect.repairResolution },
                  { text: inspectionDefect.repairComment },
                  { text: inspectionDefect.repairWorkOrder },
                  inspectionDefect.photos.map(photo => ({ image: photo.sanitizedImage.changingThisBreaksApplicationSecurity, width: 90, height: 90 }))
                ])),
              ]
            }
          },
        ]
        : [
          { text: `${translateAndFormat('there are no inspection defects present on this asset in this inspection', 'capitalize')}` }
        ],
      { text: '\n' },
    ] as any[]));
  }

  private buildTelemetryContent(telemetryList: TelemetryPrintViewModel[]) {
    if(!(telemetryList && telemetryList.length)) {
      return [];
    }

    return [
      { text: `${translateAndFormat('telemetry info', 'title')}` },
      {
        table: {
          headerRows: 1,
          body: [
            // Table headers
            [
              { text: `${translateAndFormat('telemetry type', 'title')}`, bold: true },
              { text: `${translateAndFormat('relevant value', 'title')}`, bold: true },
              { text: `${translateAndFormat('latitude', 'title')}`, bold: true },
              { text: `${translateAndFormat('longitude', 'title')}`, bold: true },
              { text: `${translateAndFormat('elevation', 'title')}`, bold: true },
              { text: `${translateAndFormat('horizontal accuracy', 'title')}`, bold: true },
              { text: `${translateAndFormat('timestamp', 'title')}`, bold: true },
            ],
            // Table data
            ...telemetryList.map(telemetry => ([
              { text: telemetry.telemetryType },
              { text: telemetry.relevantValue },
              { text: telemetry.latitude },
              { text: telemetry.longitude },
              { text: telemetry.elevation },
              { text: telemetry.horizontalAccuracy },
              { text: `${telemetry.timestamp}` },
            ])),
          ]
        }
      },
      { text: '\n' },
    ];
  }
}
