import { animate, state, style, transition, trigger } from "@angular/animations";
import { SelectionModel } from "@angular/cdk/collections";
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { IDivision } from "@zonar-ui/auth/lib/models/company.model";
import { IUser } from "@zonar-ui/auth/lib/models/user.model";
import startOfDay from "date-fns/startOfDay";
import subDays from "date-fns/subDays";
import { Observable, Subject, Subscription, combineLatest } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, take } from "rxjs/operators";
import { AppState, SetCompanyAddress, SetSearchTable } from "src/app/app.state";
import { NjmvcExportDialogComponent } from "src/app/components/njmvc-export-dialog/njmvc-export-dialog.component";
import { PdfExportDialogComponent } from "src/app/components/pdf-export-dialog/pdf-export-dialog.component";
import { translateAndFormat } from "src/app/i18next";
import { formatDate } from "src/app/i18next/formatDate";
import { CustomDateRangeFilterChange } from "src/app/models/emitter-events.models";
import { ExportType } from "src/app/models/exportType";
import { LanguageDictionaryService } from "src/app/services/language-dictionary/language-dictionary.service";
import { MemCacheService } from "src/app/services/mem-cache/mem-cache.service";
import { environment } from "src/environments/environment";
import { isDefined } from "src/utils/isDefined/isDefined";
import { newDate } from "src/utils/newDate/newDate";
import { EnabledFilters } from "../../components/filter-card/filter-card.component";
import {
  BQInspection,
  Id,
  InspectionAsset,
  InspectionDefect,
  InspectionGet,
  InspectionMultiGet,
  InspectionZone,
  LangDictGet,
} from "../../models/openAPIAliases";
import { ExportService } from "../../services/export/export.service";
import { InspectionDetailsViewModelService } from "../../services/inspection-details-view-model.service";
import { SeverityIconService } from "../../services/severity-icon.service";
import { LoggerService } from "./../../services/logger.service";
import { InspectionHistoryService } from "./service/inspection-history.service";
import {
  GetInspectionListForPdfExport,
  GetInspectionListWithQueryStrings,
  GetNJMVCInspections,
  InspectionHistoryState,
  ResetNJMVCInspections,
} from "./state/inspection-history.state";

export const debounceMs = 100;
export class InspectionHistoryInspectionViewModel {
  maxSeverity: number;
  startDate: string;
  startTime: string;
  assetId?: string;
  assetName?: string;
  assetType?: string;
  assetViewModel: InspectionHistoryAssetViewModel;
  inspectionType: string;
  inspectionName: string;
  inspectionId: Id;
  inspector: string;
  homeLocation: string;
  incompleteInspection: boolean;
  index?: number;
}

export class InspectionHistoryAssetViewModel {
  assets: InspectionAsset[]; // view model?
  multipleAssets: boolean;
  firstAssetId: string;
  firstAssetName: string;
  firstAssetType: string;
  firstAssetCategory: string;
  assetsExceptFirst: InspectionMultiGet["inspectionAssets"];
}

// state
export interface InspectionHistorySortState {
  sort: string;
  sortActive: string;
  sortDirection: string;
}

const stateKey = "inspection-history";

@Component({
  selector: "app-inspection-history",
  templateUrl: "./inspection-history.component.html",
  styleUrls: ["./inspection-history.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    // expandable rows example: https://material.angular.io/components/table/examples
    // added void: https://github.com/angular/components/issues/11990
    trigger("detailExpand", [
      state("collapsed, void", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
      transition("expanded <=> void", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
    trigger("rotatedState", [
      state("down", style({ transform: "rotate(0)" })),
      state("up", style({ transform: "rotate(180deg)" })),
      transition("down <=> up", animate("225ms cubic-bezier(0.4,0.0,0.2,1)")),
    ]),
  ],
})
export class InspectionHistoryComponent implements OnInit, OnDestroy {
  @Select(AppState.selectUserDivisions) userDivisions$: Observable<Array<IDivision>>;
  @Select(AppState.selectUserHasAllDivisions) userHasAllDivisions$: Observable<boolean>;
  @Select(AppState.selectUserRoles) selectUserRoles$: Observable<Array<string>>;

  betaBanner = false;
  companyId: string;
  config = {
    initialSelection: [],
    allowMultiSelect: true,
  };
  currentEndDate = newDate();
  currentPageSize: number;
  currentQueryStrings = new URLSearchParams({
    page: "1",
    perPage: "20",
  });
  currentStartDate = startOfDay(subDays(newDate(), 7));
  debounceFilterUpdates$ = new Subject<void>();
  defaultState: InspectionHistorySortState = {
    sort: this.getQueryStringForFieldSort("start-date", "desc"),
    sortActive: "start-date",
    sortDirection: "desc",
  };
  detailedPDF = true;
  displayedColumns = [
    "select",
    "max-severity",
    "start-date",
    "start-time",
    "asset-number",
    "asset-type",
    "inspection-name",
    "inspection-type",
    "inspector-name",
    "home-location",
    "chevron",
  ];
  enabledFilters: EnabledFilters = {
    assetId: false,
    assetType: false,
    customDateRange: false,
    dateRange: false,
    defectType: false,
    inspectionType: false,
    inspectionName: false,
    homeLocation: false,
    inspector: false,
    page: "",
    severity: false,
    tableFilter: false,
    resetFilterButton: false,
    searchBar: false,
  };
  inspectionListCount = 0;
  inspections: InspectionGet[];
  isInitiallyLoading = true;
  modifySearchString: string = translateAndFormat("please modify your search and try again", "capitalize");
  pageIndex = 0;
  pageSize = 20;
  pageSizeOptions = [10, 20, 50];
  rotateState: string[] = [];
  userDivisions: ReadonlyArray<IDivision> = [];
  userHasAllDivisions = false;
  selectionModel = new SelectionModel(this.config.allowMultiSelect, this.config.initialSelection); // Checkbox column
  sortActive = "start-date";
  sortDirection = "desc";
  state: InspectionHistorySortState;
  subscriptions = new Subscription();
  user: IUser;
  showNJMVC: boolean;

  private dataSource = new MatTableDataSource();
  private readonly searchTables = [
    {
      tableName: "assets",
      tableQueryName: "assets",
      displayTableName: translateAndFormat("assets", "capitalize"),
      displayFieldNames: ["assetName"],
      fieldNames: ["assetName"],
    },
    {
      tableName: "inspectors",
      tableQueryName: "inspectors",
      displayTableName: translateAndFormat("inspectors", "capitalize"),
      displayFieldNames: ["inspectorFirstName", "inspectorLastName"],
      fieldNames: ["inspectorFirstName", "inspectorLastName"],
    },
    {
      tableName: "divisions",
      tableQueryName: "divisions",
      displayTableName: translateAndFormat("divisions", "capitalize"),
      displayFieldNames: ["divisionName"],
      fieldNames: ["divisionName"],
    },
  ] as const;

  @Select(AppState.getSelectedCompanyId) selectedCompanyId$: Observable<string>;
  @Select(AppState.selectLanguageDictionary) languageDictionary$: Observable<LangDictGet>;
  @Select(AppState.selectUser) user$: Observable<IUser>;
  @Select(InspectionHistoryState.getInspectionList) inspectionList$: Observable<InspectionGet[]>;
  @Select(InspectionHistoryState.getInspectionListCount) inspectionListCount$: Observable<number>;
  @Select(InspectionHistoryState.getNJMVCInspections) njmvcInspections$: Observable<ReadonlyArray<BQInspection>>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChildren("matCheckbox") checkboxes; // type?

  constructor(
    private languageDictionaryService: LanguageDictionaryService,
    private router: Router,
    private severityIconService: SeverityIconService,
    public dialog: MatDialog,
    public exportService: ExportService,
    public inspectionDetailsViewModelService: InspectionDetailsViewModelService,
    public inspectionListService: InspectionHistoryService,
    public logger: LoggerService,
    public memCacheService: MemCacheService,
    public myElement: ElementRef,
    public store: Store,
  ) { }

  ngOnInit() {
    this.currentPageSize = 20;
    this.getDataSource().sort = this.sort;
    this.sort.disableClear = true;
    this.getDataSource().paginator = this.paginator;
    this.memCacheService.setValue("isLoadingPage", true);

    // only server-side sorting
    this.getDataSource().sortingDataAccessor = () => null;

    this.enabledFilters = {
      assetId: false,
      assetType: true,
      customDateRange: false,
      dateRange: true,
      defectType: false,
      inspectionType: true,
      inspectionName: true,
      homeLocation: false,
      inspector: false,
      page: "inspection-history",
      severity: true,
      tableFilter: true,
      resetFilterButton: false,
      searchBar: true,
    };

    this.subscriptions.add(
      this.inspectionListCount$.subscribe((count: number) => {
        this.inspectionListCount = count;

        // initialize all triangles in view to down since no expansion should happen on init
        this.rotateState = new Array(count).fill("down");
      }),
    );

    this.subscriptions.add(
      this.user$.subscribe(user => {
        this.user = user;
      }),
    );

    this.subscriptions.add(
      combineLatest([
        this.selectedCompanyId$,
        this.userDivisions$,
        this.userHasAllDivisions$,
        this.selectUserRoles$,
      ])
        .pipe(
          filter(
            ([companyContext, userDivisions, userHasAllDivisions, userRoles]) =>
              isDefined(companyContext) &&
              isDefined(userDivisions) &&
              isDefined(userHasAllDivisions) &&
              isDefined(userRoles),
          ),
          distinctUntilChanged(),
        )
        .subscribe(([selectedCompanyId, userDivisions, userHasAllDivisions, userRoles]) => {
          this.memCacheService.clear(stateKey);
          this.companyId = selectedCompanyId;
          this.userDivisions = userDivisions;
          this.userHasAllDivisions = userHasAllDivisions;
          this.betaBanner = selectedCompanyId === "0b420f45-2cb4-4aa4-8b2f-c7e178e314a5";
          this.showNJMVC =
            environment.NJMVC.whitelist.companies.includes(selectedCompanyId) &&
            environment.NJMVC.whitelist.roleIds.includes(userRoles[0]); //dur4033, dur4034, dur4036, dur4037, dur4039, dur4070, dur4099

          this.updateTable(this.currentQueryStrings, true);
        }),
    );

    // debounce filter updates - multiple will be generated when the filter card is cleared
    this.subscriptions.add(
      this.debounceFilterUpdates$
        .pipe(debounceTime(debounceMs))
        .subscribe(this.handleFilterValuesDebounced.bind(this)),
    );

    this.getDataSource().sort = this.sort;
    this.getDataSource().paginator = this.paginator;

    this.subscriptions.add(
      this.languageDictionary$.pipe(filter(isDefined)).subscribe(langDict => {
        if(langDict) {
          this.subscriptions.add(
            this.inspectionList$
              .pipe(
                distinctUntilChanged(
                  (previous, current) => JSON.stringify(previous) === JSON.stringify(current),
                ),
              )
              .subscribe((inspections: InspectionGet[]) => {
                this.inspectionListSubscribeHandler(inspections);
              }),
          );
        }
      }),
    );

    this.initState();

    // listen for requests to clear state
    this.subscriptions.add(
      this.memCacheService
        .cleared$()
        .pipe(filter(context => context === "inspection-history"))
        .subscribe(() => {
          this.memCacheService.setValue(stateKey, this.defaultState);

          this.initState();
        }),
    );

    this.subscriptions.add(
      this.memCacheService.cacheChanged$(stateKey).subscribe((value: InspectionHistorySortState) => {
        this.state = value;
      }),
    );
  }

  initState() {
    this.state = this.getState() ?? {
      sort: this.currentQueryStrings.get("sort"),
      sortActive: this.sortActive,
      sortDirection: this.sortDirection,
    };

    this.sortActive = this.state.sortActive;
    this.sortDirection = this.state.sortDirection;
    if(this.state.sort) {
      this.currentQueryStrings.set("sort", this.state.sort);
    }

    this.store.dispatch(new SetSearchTable(this.searchTables));
  }

  flushState() {
    this.memCacheService.setValue<InspectionHistorySortState>(stateKey, {
      ...this.defaultState,
      sort: this.currentQueryStrings.get("sort"),
      sortActive: this.sortActive,
      sortDirection: this.sortDirection,
    });
  }

  getState() {
    return this.memCacheService.getValue<InspectionHistorySortState>(stateKey);
  }

  inspectionListSubscribeHandler(inspections: InspectionGet[]) {
    // necessary because inspections is null initially
    if(inspections !== null && inspections !== undefined) {
      this.isInitiallyLoading = false;

      if(inspections && inspections.length > 0) {
        this.inspections = inspections;

        inspections.forEach((inspection: InspectionGet) => {
          if(inspection.inspectionAssets[0].inspectionZones) {
            inspection.inspectionAssets[0].inspectionZones.forEach((zone: InspectionZone) => {
              if(zone.inspectionComponents) {
                zone.inspectionComponents.map(({ inspectionDefects }) =>
                  inspectionDefects?.forEach((defect: InspectionDefect) => {
                    if(defect.defectMedia && defect.defectMedia.length > 0) {
                      if(defect.defectMedia.length > 1) {
                        console.log("---------------------------");
                        console.log(
                          "this asset has more than one defect photo in it's inspection:",
                        );
                        console.log(inspection.inspectionAssets[0].asset.assetName);
                        // console.log(defect.defectMedia)
                        console.log("---------------------------");
                      }
                    }
                  }),
                );
              }
            });
          }
        });

        this.getDataSource().data = this.getInspectionViewModels(
          inspections as unknown as InspectionMultiGet[], // This type cast should not be made, but I'm adding it since the types are wrong, the IDE is complaining, but the code is working :woman-shrugging:
        );
      } else {
        // inspections has been cleared
        this.inspections = [];
        this.getDataSource().data = [];
      }
    }
  }

  // Checks if all rows are selected
  isAllSelected(): boolean {
    const numSelected = this.selectionModel.selected.length;
    const numRows = this.getDataSource().data.length;
    return numSelected === numRows;
  }

  // Toggles between all and none selected
  masterToggle(): void {
    this.isAllSelected()
      ? this.selectionModel.clear()
      : this.getDataSource().data.forEach(row => this.selectionModel.select(row));
  }

  // for aria-labels
  checkboxLabel(row: InspectionHistoryInspectionViewModel): string {
    if(!row) {
      // master checkbox
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    } else {
      // we might need to re-enable this for proper aria labels. Disabling right now because our view model doesn't support 'position'
      // return `${this.selectionModel.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`
      return `${this.selectionModel.isSelected(row) ? "deselect" : "select"} row`;
    }
  }

  handleCheckboxChange(event: MouseEvent, inspection: InspectionGet) {
    return event ? this.selectionModel.toggle(inspection) : null;
  }

  handleCheckboxClick(event: MouseEvent) {
    event.stopPropagation();
  }

  getSeverityIcon(currentMaxSeverity: number): string {
    return this.severityIconService.getSeverityIcon(currentMaxSeverity);
  }

  openPdfExportDialog(): void {
    const dialogRef = this.dialog.open(PdfExportDialogComponent, {
      width: "580px",
      data: {
        totalInspectionCount: this.inspectionListCount,
        selectedInspectionCount: this.selectionModel.selected.length,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(filter(isDefined))
      .subscribe(dialogueResult => {
        // type?

        if(dialogueResult === "export-all") {
          this.exportFullSetOfInspections();
        } else if(dialogueResult === "export-selected") {
          this.exportCurrentlySelectedInspections();
        } else {
          // user closed dialogue without selecting an option
        }
      });
  }

  exportNJMVC(error = false) {
    const dialogRef = this.dialog.open(NjmvcExportDialogComponent, {
      width: "680px",
      data: { error },
    });

    dialogRef
      .afterClosed()
      .pipe(filter(isDefined))
      .subscribe(dialogueResult => {
        if(dialogueResult) {
          this.store.dispatch(new GetNJMVCInspections(this.companyId, dialogueResult));
          this.store.dispatch(new SetCompanyAddress(dialogueResult.companyAddress));
          this.njmvcInspections$
            .pipe(
              filter(inspections => isDefined(inspections)),
              take(1),
            )
            .subscribe(inspections => {
              if((inspections?.length ?? 0) > 0) {
                this.router.navigate(["/njmvc-drivers-vehicle-inspection-report"]);
              } else {
                this.store.dispatch(new ResetNJMVCInspections());
                this.exportNJMVC(true);
              }
            });
        }
      });
  }

  exportFullSetOfInspections(): void {
    const queryStrings = new URLSearchParams(this.currentQueryStrings);
    queryStrings.set("perPage", "1000");

    if(this.companyId) {
      this.store.dispatch(
        new GetInspectionListForPdfExport(
          queryStrings,
          this.companyId,
          environment.environmentConstants.APP_ENDPOINT_EVIR,
        ),
      ).subscribe(() => {
        const inspectionIds = this.store.selectSnapshot(InspectionHistoryState.getInspectionListForPdfExport)
          .map(inspection => inspection.id);
        this.exportService.exportDetailedInspectionData(inspectionIds, this.companyId);
      });
    } else {
      this.logger.log(
        `Attempting to get the inspections for the PDF view, but company ID hasn't been populated yet`,
      );
    }
  }

  exportCurrentlySelectedInspections(): void {
    this.exportService.exportDetailedInspectionData(this.currentlySelectedInspections(), this.companyId);
  }

  currentlySelectedInspections() {
    const inspections = this.selectionModel.selected;

    const inspectionIds: Id[] = inspections
      .sort((a, b) => a.index - b.index)
      .map((inspection: InspectionHistoryInspectionViewModel) => inspection.inspectionId);

    return inspectionIds;
  }

  exportCSV() {
    this.exportService.exportInspectionData(this.currentlySelectedInspections(), this.companyId, ExportType.CSV);
  }

  exportPDF() {
    this.exportService.exportInspectionData(this.currentlySelectedInspections(), this.companyId, ExportType.PDF);
  }

  getQueryStringForFieldSort(field: string, direction: string): string {
    let sort = "";

    switch(field) {
      case "max-severity":
        sort += `severity`;
        break;
      case "start-date":
        sort += `startTime`;
        break;
      case "start-time":
        sort += `startTime`;
        break;
      case "asset-number":
        sort += `assetName`;
        break;
      case "asset-type":
        sort += `zoneLayout`;
        break;
      case "inspection-type":
        sort += `inspectionType`;
        break;
      case "inspection-name":
        sort += `inspectionName`;
        break;

      case "inspector-name":
        sort += `inspectorName`;
        break;
      // intentionally not sorting division/asset location/home location
      default:
        break;
    }

    return sort ? `${sort}.${direction}` : sort;
  }

  onMatSortChange(event: Sort): void {
    this.currentQueryStrings.set("sort", this.getQueryStringForFieldSort(event.active, event.direction));

    this.updateTable(this.currentQueryStrings);
    this.selectionModel.clear();

    this.sortActive = event.active;
    this.sortDirection = event.direction;

    this.flushState();
  }

  onPageIndexChange(pageIndex: number) {
    this.pageIndex = pageIndex;
  }

  onPageSizeChange(pageSize: number) {
    this.pageSize = pageSize;
  }

  onQueryChange(query: URLSearchParams): void {
    this.updateTable(new URLSearchParams(Object.fromEntries([...this.currentQueryStrings, ...query])));

    // reset selected rows
    this.selectionModel.clear();

    // reset chevrons
    this.rotateState = new Array(this.inspectionListCount).fill("down");
  }

  public handleFilterValuesDebounced(): void {
    this.pageIndex = 1;
    this.updateTable(this.currentQueryStrings, true);
  }

  public handleFilterValues(filterQueries: {
    queryParams: URLSearchParams;
    customDateRangeFilter: CustomDateRangeFilterChange;
  }): void {
    // capture the query strings here to avoid race conditions with respect to other event emissions
    this.currentQueryStrings = new URLSearchParams();

    if(this.state.sort) {
      this.currentQueryStrings.set("sort", this.state.sort);
    }

    filterQueries.queryParams.forEach((value, key) => this.currentQueryStrings.set(key, value));

    this.debounceFilterUpdates$.next();
  }

  updateTable(currentQueryStrings: URLSearchParams, resetPaginator: boolean = false): void {
    if(this.companyId) {
      this.selectionModel.clear();

      // reset paginator on filter change back to first page
      if(resetPaginator) {
        this.currentQueryStrings.set("page", "1");
        this.currentQueryStrings.set("perPage", this.pageSize.toString());
        this.pageIndex = 0;
      }

      const queryStrings = new URLSearchParams(currentQueryStrings);

      if(currentQueryStrings.has("startTime")) {
        if(!currentQueryStrings.has("divisionIds")) {
          // if the user hasn't selected a division from the search bar, include the account selector divisionId in all inspection requests
          if(!this.userHasAllDivisions) {
            queryStrings.set("divisionIds", this.userDivisions.map(division => division.id).join(","));
            queryStrings.set("allChildren", "true");
          }
        }

        this.store.dispatch(
          new GetInspectionListWithQueryStrings(
            queryStrings,
            this.companyId,
            environment.environmentConstants.APP_ENDPOINT_EVIR,
          ),
        );
      }
    }
  }

  getInspectionViewModels(inspections: InspectionMultiGet[]): InspectionHistoryInspectionViewModel[] {
    return inspections.map((inspection, index) => {
      const assetViewModel: InspectionHistoryAssetViewModel = {
        assets: inspection.inspectionAssets ? inspection.inspectionAssets : null,
        assetsExceptFirst: inspection.inspectionAssets.slice(1),
        firstAssetCategory: inspection.inspectionAssets[0].asset.assetCategory,
        firstAssetId: inspection.inspectionAssets[0].asset.assetId,
        firstAssetName: inspection.inspectionAssets[0].asset.assetName,
        firstAssetType: this.languageDictionaryService.getTranslations(
          inspection.inspectionAssets[0].zoneLayoutName,
        ),
        multipleAssets: inspection.inspectionAssets.length <= 1 ? false : true,
      };

      const inspectionViewModel: InspectionHistoryInspectionViewModel = {
        maxSeverity: inspection.maxSeverity,
        startDate: formatDate(this.getStartTime(inspection), "P"),
        startTime: formatDate(this.getStartTime(inspection), "pp"),
        assetViewModel: assetViewModel,
        inspectionType: this.getInspectionType(inspection),
        inspectionName: this.getInspectionName(inspection),
        inspectionId: inspection.id,
        inspector: `${inspection.inspectorLastName}, ${inspection.inspectorFirstName}`,
        homeLocation: inspection?.inspectionAssets?.[0]?.asset?.assetDivision?.divisionName ?? "",
        incompleteInspection: this.isInspectionIncomplete(inspection),
        index: index,
      };

      return inspectionViewModel;
    });
  }

  isInspectionIncomplete(inspection: InspectionMultiGet): boolean {
    let isIncomplete = false;

    inspection.inspectionAssets.forEach((asset: InspectionAsset) => {
      if(asset.inspectionZones) {
        asset.inspectionZones.forEach((zone: InspectionZone) => {
          if(zone.verificationType === "uninspected") {
            isIncomplete = true;
          }
        });
      }
    });

    return isIncomplete;
  }

  getStartTime(inspection: InspectionMultiGet): Date {
    const relevantInspectionInfo = inspection.inspectionInfo.find(x => {
      return x.action === "start";
    });

    if(relevantInspectionInfo) {
      return newDate(relevantInspectionInfo.telemetry.timestamp);
    } else {
      return null;
    }
  }

  getInspectionType(inspection: InspectionMultiGet): string {
    return (
      inspection.inspectionDetail.inspectionDetailMetadata?.inspectionType ?? this.getInspectionName(inspection)
    );
  }

  getInspectionName(inspection: InspectionMultiGet): string {
    return this.languageDictionaryService.getTranslations(inspection.inspectionDetail.inspectionDetailName);
  }

  // rotate the chevron that corresponds with the index of clicked row
  // e.g. rotateState = ['down', 'down', 'down'], user clicks second row, rotateState now ['down', 'up', 'down']
  // triggers animation for clicked chevron
  public rotate(i: number): void {
    this.rotateState = this.rotateState.map((rotation: string, k: number) => {
      if(k === i) {
        return rotation === "down" ? "up" : "down";
      } else {
        return rotation;
      }
    });
  }

  // when user sorts,repairs, or pages, close any expanded element and reset all chevrons to down position
  public resetAnimations(): void {
    this.rotateState = new Array(this.inspectionListCount).fill("down");
    this.selectionModel.clear();
  }

  // trigger slide in slide out animation and set class to hide or show
  // public expansionAnimation(element, expandedElement): string {
  public expansionAnimation(_element, index): string {
    return this.rotateState[index] === "up" ? "expanded" : "collapsed";
  }

  public getDividerClass(row, i: number): string {
    if(this.selectionModel.isSelected(row) && this.rotateState[i] === "down") {
      return "highlight";
    } else if(this.selectionModel.isSelected(row) && this.rotateState[i] === "up") {
      return "white-divider-highlight";
    } else {
      return null;
    }
  }

  public getDataSource() {
    return this.dataSource;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
