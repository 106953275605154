export const environment = {
	production: false,
	environment: "development",
	shadow: false,
	region: "NA",
	brand: {
		copyright: "Zonar Systems",
		"footer-logo-filename": "zonar-footer-logo.svg",
		name: "zonar",
		"support-email": "customercare@zonarsystems.com",
		"support-phone-number": {
			"en-us": "877-843-3847",
			default: "877-843-3847",
		},
	},
	analytics: {
		google: true,
		pendo: true,
	},
	localeConfig: {
		links: {
			enabled: false,
			default: "",
		},
	},
	datadog: {
		applicationId: "cfe1252a-5130-46cc-9e04-1f65c6166cbc",
		clientToken: "pub19548a370a50698453080068542cd4ce",
		site: "us5.datadoghq.com",
	},
	pendo: {
		url: "https://cdn.pendo.io/agent/static/",
		key: "47e63a49-4477-47c2-4ea6-0f032e1785d5",
	},
	environmentConstants: {
		APP_APPLICATION_ID: "21fc7845-66c9-4862-bc71-955fda024daa",
		APP_ENDPOINT_CORE_BASE: "https://api-dev.zonarsystems.net/core",
		APP_ENDPOINT_CORE: "https://api-dev.zonarsystems.net/core/v1",
		APP_ENDPOINT_EVIR_BASE: "https://api-dev.zonarsystems.net/evir",
		APP_ENDPOINT_EVIR: "https://api-dev.zonarsystems.net/evir/v1",
		APP_ROLE_ASSIGN_MECHANIC: "00fe5a11-a180-4528-812e-6568c2ad2fd5",
		APP_ROLE_REPAIR_DEFECT_MECHANIC: "1473b96b-ed70-4669-90d2-1c5f2bbf546f",
		APP_URL: "https://evir-web.dev.zonarsystems.net",
		ZONAR_MAP_URL: "https://maps.dev.zonarsystems.net/assets/",
	},
	auth0: {
		AUDIENCE: "http://apidev.zonarsystems.net/",
		CLIENT_ID: "MEet5vOBvHwqp6qAicaWk3AQbqSckHqb",
		DOMAIN: "zonar-dev.auth0.com",
	},
	i18n: {
		supportedLanguages: ["en-US", "en-GB", "de-DE", "fr-FR", "es-ES", "it-IT"],
		defaultLanguage: "en-US",
	},
	NJMVC: {
		whitelist: {
			companies: [],
			roleIds: [],
		},
	},
};
